<template>
    <div class="park-meeting-dialog">
        <el-form :model="ruleForm" :rules="rules" label-position="top" ref="ruleForm" label-width="100px">
            <el-row :gutter="48">
                <el-col :span="12">
                    <el-form-item label="会议来源" prop="source">
                        <el-radio-group v-model="ruleForm.source" :disabled="currentState === 'view'">
                            <el-radio label="后台客户预定"></el-radio>
                            <el-radio label="OA内部预定"></el-radio>
                            <el-radio label="小程序预定" v-if="currentState === 'view'"></el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="48">
                <el-col :span="12">
                    <el-form-item label="会议室选择" prop="conferenceName">
                        <el-select v-model="ruleForm.conferenceName" :disabled="currentState === 'view'" placeholder="请选择" @change="initTimeList">
                            <el-option v-for="item in conferenceNameOptions" :key="item.value" :label="item.label"
                                :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="48">
                <el-col :span="12">
                    <el-form-item label="预定日期" prop="scheduledDate">
                        <el-date-picker v-model="ruleForm.scheduledDate" :disabled="currentState === 'view'" type="date" @change="getDataOptions"
                            placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="会议时间" prop="meetingTime">
                        <el-select v-model="ruleForm.meetingTime" multiple  :disabled="currentState === 'view'" placeholder="请选择">
                            <el-option v-for="item in DataOptions" :key="item.value" :label="item.label"
                                :disabled="item.disabled" :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <template v-if="ruleForm.source !== 'OA内部预定'">
                <el-row :gutter="48">
                    <el-col :span="12">
                        <el-form-item label="预定人" prop="contacts">
                            <el-input v-model="ruleForm.contacts" :disabled="currentState === 'view'" placeholder="请填写预定人姓名"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="预定单位" prop="company">
                            <el-input v-model="ruleForm.company" :disabled="currentState === 'view'" placeholder="请填写预定单位"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="48">
                    <el-col :span="12">
                        <el-form-item label="预定手机号" prop="phone">
                            <el-input v-model="ruleForm.phone" :disabled="currentState === 'view'" placeholder="请填写预定人号码"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="会议用途" prop="purposes">
                            <!-- <el-input v-model="ruleForm.purposes"></el-input> -->
                            <el-select v-model="ruleForm.purposes" :disabled="currentState === 'view'" placeholder="请选择">
                                <el-option v-for="item in purposespurposesList" :key="item.value" :label="item.label"
                                    :value="item.label">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="48">
                    <el-col :span="12">
                        <el-form-item label="会议人数" prop="number">
                            <!-- <el-input v-model="ruleForm.number"></el-input> -->
                            <el-select v-model="ruleForm.number" :disabled="currentState === 'view'" placeholder="请选择">
                                <el-option v-for="item in numberList" :key="item.value" :label="item.label"
                                    :value="item.label">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="48">
                    <el-col :span="24">
                        <el-form-item label="备注 " prop="remark">
                            <el-input type="textarea" :rows="5" :disabled="currentState === 'view'" placeholder="请写会议室预定的任何要求，例如特殊设备设施、空间布置和定制茶歇等。"
                                v-model="ruleForm.remark"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </template>

            <el-form-item align="right" style="margin-top: 120px;" v-if="currentState !== 'view'">
                <el-button type="primary" @click="clickStep('save')">保存</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import {
    getMeetingData,
    getMeetingTimes,
    addConferenceDestined,
    getConferenceListDetail,
    getCalendarListDetail
} from "@/api/ruge/jwPark/MeetingRoom";
import { dateFormat } from "@/filters/index";
export default {
    name: "meetingDialog",
    props: {
        dialogStatus: {
            type: String,
            required: true,
        },
        id: {
            required: false,
        },
        detailsList: {
            required: false,
        }
    },
    data() {
        return {
            // pickerOptions: { 
            //     disabledDate(time) { 
            //         // 禁用日期逻辑
            //         const currentDate = new Date();
            //         const minDate = new Date();
            //         // 设置最大可选日期为15天后
            //         const maxDate = new Date();
            //         maxDate.setDate(currentDate.getDate() + 17);
            //         // 设置最小可选日期为当前日期
            //         minDate.setDate(currentDate.getDate() + 2);
            //         return time.getTime() < minDate.getTime() || time.getTime() > maxDate.getTime();
            //     }
            // },
            purposespurposesList: [
                {
                    value: '1',
                    label: '培训'
                },
                {
                    value: '2',
                    label: '研讨'
                },
                {
                    value: '3',
                    label: '发布会'
                },
                {
                    value: '4',
                    label: '宣传'
                },
                {
                    value: '5',
                    label: '招投标'
                },
                {
                    value: '6',
                    label: '其他'
                },
            ],
            numberList: [
                {
                    value: '1',
                    label: '<25人'
                },
                {
                    value: '2',
                    label: '16-50人'
                },
                {
                    value: '3',
                    label: '51-100人'
                },
                {
                    value: '4',
                    label: '>100人'
                },
            ],
            conferenceNameOptions: [],
            DataOptions: [],
            meetingData: {},
            ruleForm: {
                source: "后台客户预定",
                conferenceName: "",
                scheduledDate: "",
                meetingTime: [],
                contacts: "",
                company: "",
                phone: "",
                purposes: "",
                number: "",
                remark: "",
                MeetingData: '',
            },
            rules: {
                source: [
                    { required: true, message: '必填项', trigger: 'change' }
                ],
                conferenceName: [
                    { required: true, message: '必填项', trigger: 'change' }
                ],
                scheduledDate: [
                    { required: true, message: '必填项', trigger: 'change' }
                ],
                meetingTime: [
                    { required: true, message: '必填项', trigger: 'change' }
                ],
                contacts: [
                    { required: true, message: '必填项', trigger: 'change' }
                ],
                company: [
                    { required: true, message: '必填项', trigger: 'change' }
                ],
                phone: [
                    { required: true, message: '必填项', trigger: 'change' }
                ],
                purposes: [
                    { required: true, message: '必填项', trigger: 'change' }
                ],
                number: [
                    { required: true, message: '必填项', trigger: 'change' }
                ],


            }
        }
    },
    created() {
        this.initMeetingData()
    },
    methods: {
        clickStep(type) {
            if (type == 'save') {
                console.log(111);
                this.$refs["ruleForm"].validate((valid) => {
                    if (valid) {
                        console.log(222);
                        let ruleFormList = {}
                        if (this.ruleForm.source == '后台客户预定') {
                            ruleFormList = {
                                // 客户预定
                                source: this.ruleForm.source == '后台客户预定' ? '2' : '3',
                                scheduledDate: this.ruleForm.MeetingData,
                                timeSlots: this.ruleForm.meetingTime,
                                purposes: this.ruleForm.purposes,
                                contacts: this.ruleForm.contacts,
                                phone: this.ruleForm.phone,
                                conferenceId: this.meetingData.value,
                                company: this.ruleForm.company,
                                number: this.ruleForm.number,
                                remark: this.ruleForm.remark,
                            }
                        } else {
                            ruleFormList = {
                                conferenceId: this.meetingData.value,
                                source: this.ruleForm.source == '后台客户预定' ? '2' : '3',
                                scheduledDate: this.ruleForm.MeetingData,
                                timeSlots: this.ruleForm.meetingTime,
                            }
                        }
                        addConferenceDestined(ruleFormList).then(res => {
                            console.log(res);
                            if (res.code == 200) {
                                this.$emit("close", true);
                                this.$message({
                                    type: "success",
                                    message: res.data,
                                });
                            }
                        })
                    }
                })
            }
        },
        initMeetingData() {
            getMeetingData().then(res => {
                console.log(res, '会议室列表');
                res.data.map(item => {
                    let obj = {
                        value: item.id,
                        label: item.conferenceName,
                        floor: item.floor
                    }
                    this.conferenceNameOptions.push(obj)
                })
                console.log(this.conferenceNameOptions, 'conferenceNameOptions');
            })
        },

        getDataOptions(val) {
            console.log(val);
            console.log(dateFormat(val, "YYYY-MM-DD"));
            this.ruleForm.MeetingData = dateFormat(val, "YYYY-MM-DD")
            this.initTimeList(this.meetingData)

        },
        initTimeList(e) {
            this.meetingData = e
            if (this.ruleForm.conferenceName !== "" && this.ruleForm.MeetingData !== "") {
                getMeetingTimes({
                    id: e.value,
                    floor:e.floor,
                    scheduledTime: this.ruleForm.MeetingData
                }).then(res => {
                    this.DataOptions = []
                    res.data.map(item => {
                        let obj = {
                            value: item.text,
                            label: item.text,
                            disabled: item.disabled
                        }
                        this.DataOptions.push(obj)
                    })
                })
            }
        },
        getMeetingDetail(detailsList) {
            console.log(detailsList, "555");
            getCalendarListDetail({ 
                destinedDate: detailsList.destinedDate,
                conferenceName: detailsList.siteName,
                timeSlot: detailsList.k
             }).then(res => {
                console.log(res,'详情');
                if(res.data.source == 2) {
                    this.ruleForm.source = "后台客户预定",
                    this.ruleForm.conferenceName = res.data.conferenceId,
                    this.ruleForm.scheduledDate = res.data.scheduledDate,
                    this.ruleForm.MeetingData = dateFormat(res.data.scheduledDate, "YYYY-MM-DD"),
                    this.ruleForm.meetingTime = res.data.timeSlots,
                    this.ruleForm.contacts = res.data.contacts,
                    this.ruleForm.company = res.data.company,
                    this.ruleForm.phone = res.data.phone,
                    this.ruleForm.purposes = res.data.purposes,
                    this.ruleForm.number = res.data.number,
                    this.ruleForm.remark = res.data.remark
                } else if (res.data.source == 3) {
                    this.ruleForm.source = "OA内部预定",
                    this.ruleForm.conferenceName = res.data.conferenceId,
                    this.ruleForm.scheduledDate = res.data.scheduledDate,
                    this.ruleForm.MeetingData = dateFormat(res.data.scheduledDate, "YYYY-MM-DD"),
                    this.ruleForm.meetingTime = res.data.timeSlots
                } else if (res.data.source == 1) {
                    this.ruleForm.source = "小程序预定",
                    this.ruleForm.conferenceName = res.data.conferenceId,
                    this.ruleForm.scheduledDate = res.data.scheduledDate,
                    this.ruleForm.MeetingData = dateFormat(res.data.scheduledDate, "YYYY-MM-DD"),
                    this.ruleForm.meetingTime = res.data.timeSlots,
                    this.ruleForm.contacts = res.data.contacts,
                    this.ruleForm.company = res.data.company,
                    this.ruleForm.phone = res.data.phone,
                    this.ruleForm.purposes = res.data.purposes,
                    this.ruleForm.number = res.data.number,
                    this.ruleForm.remark = res.data.remark
                }
            })
        }
    },
    watch: {
        id: {
            handler(id) {
                if (!id) return;
                // this.getMeetingDetail(id);
            },
            immediate: true,
        },
        dialogStatus: {
            handler(val) {
                this.currentState = val;
            },
            immediate: true,
        },
        detailsList: {
            handler(detailsList) {
                if (!detailsList) return;
                this.getMeetingDetail(detailsList);
            },
            immediate: true,
        }
    }
}
</script>

<style scoped lang="less">
.park-meeting-dialog {}
</style>