import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';
/**
 * 会议配置 - 列表
 * @param params
 */
export function getMeetingList(params){
    return request({
        url: envInfo.bgApp.customerjwPath + '/conference/config/getConferenceConfigList',
        method: 'get',
        params,
    });
}

/**
 * 会议配置 - 新增
 * @param params
 */
export function addMeetingItem(params) {
    return request({
      url: envInfo.bgApp.customerjwPath + '/conference/config/insert',
      method: 'post',
      data: params,
    });
  }

/**
 * 会议配置 - 编辑详情
 * @param params
 */
export function getMeetingDetail(params){
    return request({
        url: envInfo.bgApp.customerjwPath + '/conference/config/getConferenceDetail',
        method: 'get',
        params,
    });
}

/**
 * 会议配置 - 上架
 * @param params
 */
export function editInvoiceStatus(params){
    return request({
        url: envInfo.bgApp.customerjwPath + '/conference/config/status',
        method: 'get',
        params,
    });
}

/**
 * 会议配置 - 删除
 * @param params
 */
export function deleteMeetingActivity(params){
    return request({
        url: envInfo.bgApp.customerjwPath + '/conference/config/delete',
        method: 'get',
        params,
    });
}

/**
 * 会议配置 - 导出
 * @param params
 */
export function meetingListExport(params){
    return request({
        url: envInfo.bgApp.customerjwPath + '/conference/config/export',
        method: 'get',
        params,
    });
}

/**
 * 预定列表 - 列表
 * @param params
 */
export function getMeetingReservationList(params){
    return request({
        url: envInfo.bgApp.customerjwPath + '/conference/destined/getConferenceDestinedList',
        method: 'get',
        params,
    });
}

/**
 * 预定列表 - 取消预定
 * @param params
 */
export function cancelMeeting(params){
    return request({
        url: envInfo.bgApp.customerjwPath + '/conference/destined/Cancel',
        method: 'get',
        params,
    });
}


/**
 * 预定列表 - 会议室列表
 * @param params
 */
export function getMeetingData(params){
    return request({
        url: envInfo.bgApp.customerjwPath + '/conference/config/getConfigList',
        method: 'get',
        params,
    });
}

/**
 * 预定列表 - 会议室时间段
 * @param params
 */
export function getMeetingTimes(params){
    return request({
        url: envInfo.bgApp.customerjwPath + '/conference/destined/getTimeList',
        method: 'get',
        params,
    });
}

/**
 * 会议列表 - 新增
 * @param params
 */
export function addConferenceDestined(params) {
    return request({
      url: envInfo.bgApp.customerjwPath + '/conference/destined/addConferenceDestined',
      method: 'post',
      data: params,
    });
  }

  /**
 * 预定列表 - 会议室详情
 * @param params
 */
export function getConferenceListDetail(params){
    return request({
        url: envInfo.bgApp.customerjwPath + '/conference/destined/getConferenceDestinedDetail',
        method: 'get',
        params,
    });
}

  /**
 * 预定列表 - 导出
 * @param params
 */
  export function ConferenceListExport(params){
    return request({
        url: envInfo.bgApp.customerjwPath + '/conference/destined/export',
        method: 'get',
        params,
    });
}

/**
 * 场地日历 - 获取日历时间段
 * @param params
 */
export function getScheduleList(params){
    return request({
        url: envInfo.bgApp.customerjwPath + '/conference/destined/conferenceCalendar',
        method: 'post',
        data: params,
    });
}

/**
 * 场地日历 - 获取日历场地列表
 * @param params
 */
export function getPlaceList(params){
    return request({
        url: envInfo.bgApp.customerjwPath + '/conference/config/getConfigList',
        method: 'get',
        params,
    });
}

  /**
 * 场地日历 - 会议室详情
 * @param params
 */
  export function getCalendarListDetail(params){
    return request({
        url: envInfo.bgApp.customerjwPath + '/conference/destined/getCalendarDetail',
        method: 'get',
        params,
    });
}

  /**
 * 会议室配置 - 编辑
 * @param params
 */
  export function editParkActivity(params){
    return request({
        url: envInfo.bgApp.customerjwPath + '/conference/config/update',
        method: 'post',
        data: params,
    });
}